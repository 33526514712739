.tarif {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logos-bar {
    margin: 4rem 0rem 1rem 0rem;
    display: flex;
    gap: 1.2rem;
  }

  .title {
    font-size: var(--title-size);
    font-weight: var(--title-weight);
    text-align: left !important;
  }

  .subtitle {
    display: flex;
    font-size: var(--subtitle-size);
    font-weight: var(--subtitle-weight);
    margin-top: 1rem;
  }

  .content {
    display: flex;
    margin-bottom: 0;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
  }

  .tarifs-unique {
    display: flex;
    width: 100%;
  }
  .logos-bar-item {
    display: flex;
    width: 4rem;
    height: 4rem;
    box-shadow: 0px 0px 10px 0px #d2d2d2;
    border-radius: 5rem;
    align-items: center;
    justify-content: center;
  }
  .logos-bar img {
    width: 7rem;
  }

  .tarifs-unique-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .tarifs-unique-prix {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .tarifs-unique-prix-item {
    font-size: 7.5rem;
    box-shadow: 0px 0px 10px 0px #d2d2d2;
    border-radius: 7.5rem;
    width: 15rem;
    height: 15rem;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .text {
    margin: 1.5rem 0rem;
    text-align: left;
    font-size: var(--text-size);
    font-weight: 600;
  }

  .subtext {
    font-size: var(--subtext-size);
    text-align: left;
    font-weight: 400;
  }
}

.color-green {
  color: #b0d14c;
}

.color-lightblue {
  color: #67c2e2;
}
.color-blue {
  color: #292b59;
}
.color-grey {
  color: #777777;
}
.color-orange {
  color: #f3b540;
}

.bg-color-green {
  background-color: #b0d14c;
}

.bg-color-lightblue {
  background-color: #67c2e2;
}
.bg-color-blue {
  background-color: #292b59;
}
.bg-color-grey {
  background-color: #777777;
}
.bg-color-orange {
  background-color: #f3b540;
}

@media (max-width: 575px) {
  .tarif {
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 4%;
    padding-bottom: 4%;

    .title {
      margin-top: 2rem;
      font-size: 2.5rem;
      padding-left: 0;
      margin-bottom: 0rem;
    }

    .subtitle {
      margin-top: 0rem;
      font-size: 1.5rem;
    }

    .tarifs-unique {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .tarifs-unique-prix-item {
      font-size: 7.5rem;
      box-shadow: 0px 0px 10px 0px #d2d2d2;
      border-radius: 7.5rem;
      width: 15rem;
      height: 15rem;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      margin-top: 2rem;
    }
  }
}
