.mobItem {
  display: flex !important;
  flex-direction: row;

  .text-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
  }

  .title {
    font-size: 1.4rem !important;
    margin: 0px !important;
    line-height: 1.4rem !important;
  }

  .icon {
    padding: 1rem;
  }

  .icon img {
    max-height: 3rem !important;
  }
  .text {
    border-radius: 0rem 0rem 1rem 1rem !important;
    text-align: left !important;
  }
}

@media (max-width: 575px) {
  .mobItem {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;

    .icon {
      padding: 0.2rem 0rem;
      img {
        max-height: 4rem !important;
      }
    }

    .title {
      padding: 0% 0%;
    }
  }
}
