.CookieConsent
{
    color:var(--global-blue-color);
    text-align: center;
}

.CookieConsent .btn 
{
    background-color: var(--global-blue-color);
    margin:auto;
    border-radius: 2.5rem;
    height:2rem;
    padding-bottom: 2rem;
}

.CookieConsent .modal-title {
    color:var(--global-blue-color);
}