.Menu {
  display: flex;
  width: 100%;
  padding: 10px;
  /*height: 100%;*/
  padding-left: 2.5rem;
}

.Menu .logo-content {
  flex: 100%;
}

.Menu .logo-leco {
  width: 18rem;
  margin: 1rem;
}

.Menu .menu-item {
  font-size: 12px;
  border: solid 0px;
  color: var(--global-blue-color);
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
}

.Menu .menu-item:hover {
  background-color: lightgrey;
}

.Menu .navbar {
  display: contents;
  flex-grow: 6;
}

.Menu .navbar-collapse {
  flex-direction: row-reverse;
}

.Menu .navbar-light .navbar-nav .nav-link,
.Menu .navbar-dark .navbar-nav .nav-link,
.Menu .navbar .navbar-nav .nav-link {
  color: var(--global-blue-color);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 4rem;
}

.Menu .navbar-light .navbar-toggler {
  /*background-color:var(--global-blue-color);*/
}

.Menu .nav-item:hover {
  font-weight: bold;
}

@media (max-width: 992px) {
  .Menu {
    padding-left: 0rem;

    a {
      margin-left: 4%;
    }

    .logo-leco {
      max-width: 13rem;
      margin-top: 0rem;
      margin-left: 0px;
    }
  }

  .Menu .Menu .navbar-light .navbar-nav .nav-link,
  .Menu .navbar-dark .navbar-nav .nav-link,
  .Menu .navbar .navbar-nav .nav-link {
    margin-top: 0rem;
  }
}
