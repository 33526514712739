.scroll-to-top
{

}

.to-top-button 
{
    position:fixed;
    bottom:1rem;
    right:1rem;
    z-index: 50;
    cursor: pointer;
    background-color: transparent;
    border:solid 0px;
    opacity: 0.7;
}

.to-top-button img
{
    width:3.5rem;
}