:root {
  --global-blue-color: #24275c;
  --global-lightblue-color: #3bc6e7;
  --global-green-color: #ffb400;
  --global-page-height: 25rem;
  --global-background-grey: rgb(165, 165, 165);
  --bs-gutter-x: 0rem !important;
  font-size: 18px;
  --title-size: 3.5rem;
  --subtitle-size: 2.5rem;
  --text-size: 1.2rem;
  --subtext-size: 1rem;
  --title-weight: bold;
  --subtitle-weight: bold;
  --text-weight: normal;
}

@media (max-width: 575px) {
  :root {
    font-size: 15px;
  }
}

@font-face {
  font-family: "Ink Free"; /*Can be any text*/
  src: local("InkFree"), url("../assets/fonts/ink-free.ttf") format("truetype");
}

@font-face {
  font-family: "Century Gothic"; /*Can be any text*/
  src: local("CenturyGothic"),
    url("../assets/fonts/CenturyGothic.ttf") format("truetype");
}

.App {
  font-size: 1rem;
  text-align: center;
  height: 100%;
  max-width: 100%;
  width: 100%;
  /*font-family: 'Verdana', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', sans-serif;*/
}

.row {
  --bs-gutter-x: 0rem !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: red; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

#myBtn:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
