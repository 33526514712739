.Partenaires {
  text-align: left !important;
  height: 100%;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;

  .left-content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .right-content {
    display: flex;
    flex: 1;
  }

  .content {
    display: flex;
    margin-bottom: 0;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: var(--title-size);
    font-weight: var(--title-weight);
    text-align: left !important;
  }

  .subtitle {
    display: flex;
    font-size: var(--subtitle-size);
    font-weight: var(--subtitle-weight);
    margin-top: 1rem;
  }

  .box-img {
    height: 100%;
    display: block;
    max-width: 400;
    overflow: hidden;
    width: 60%;
    margin: auto;
  }

  .nav-button {
    padding: 0;
    min-width: 1rem;
    padding: 0.5rem;
    margin: 0rem 1rem;
  }
  .nav-button svg {
    height: 4rem;
    width: 4rem;
    color: lightblue;
  }
}

a {
  color: var(--global-blue-color); /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  white-space: nowrap;
}

@media (max-width: 575px) {
  .Partenaires {
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 4%;
    padding-bottom: 4%;
    margin-top: 0rem;

    .title {
      margin-top: 2rem;
      font-size: 2.5rem;
      padding-left: 0;
      margin-bottom: 0rem;
    }

    .subtitle {
      margin-top: 0rem;
      font-size: 1.5rem;
    }

    .right-content {
      margin-top: 2rem;
    }

    .subtitle {
      margin-top: 0rem;
    }
    .content {
      flex-direction: column;
      align-items: unset;
    }
  }
}
