.crmItem {
  position: relative !important;
  flex-direction: column !important;
  width: 12rem !important;
  border-radius: 1rem !important;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px #d2d2d2;
  &:hover {
    box-shadow: 0px 0px 10px 0px #7c7c7c;
  }
  &:hover > .index {
    box-shadow: 0px 0px 10px 0px #7c7c7c;
  }

  .index {
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    background: white;
    border-radius: 1.5rem;
    margin-left: -1.25rem;
    margin-top: -1.25rem;
    font-size: 2.5rem;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #d2d2d2;

    &:hover {
      box-shadow: 0px 0px 10px 0px #7c7c7c;
    }
    &:hover > .crmItem {
      box-shadow: 0px 0px 10px 0px #7c7c7c;
    }
  }

  .header {
    border-radius: 1rem 1rem 0rem 0rem;
    min-height: 4rem;
    padding: 0.5rem;
    color: white;
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    align-items: flex-end;
  }

  .title {
    margin: 0px;
    font-size: 2rem;
    margin-bottom: -0.5rem;
    margin-top: 0.2rem;
  }

  .subtitle {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .icon {
    padding: 2rem 1rem;
  }

  .icon img {
    max-height: 6rem;
  }
  .text {
    border-radius: 0rem 0rem 1rem 1rem;
    padding: 0rem 1rem 1rem 1rem;
  }
}

.Modal {
  .modal-content {
    padding: 1rem;
    border-radius: 1rem;
  }

  .modal-container {
    .header {
      display: flex;

      .icon {
        display: flex;
        width: 4rem;
        height: 4rem;
        box-shadow: 0px 0px 10px 0px #d2d2d2;
        border-radius: 5rem;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        img {
          width: 7rem;
        }
      }
    }
    .title {
      margin: 0rem 1.5rem;
      font-size: var(--subtitle-size);
      line-height: 2rem;
      font-weight: bold;
    }

    .content {
      display: flex;
      flex-direction: column;
      .title {
        margin: 0px;
        margin-top: 2rem;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: bold;
      }
      .subtitle {
        font-size: 1.2rem;
        font-weight: 200;
      }
      .checkListTitle {
        margin: 2rem 0rem 1.5rem 0rem;
        font-weight: bold;
        font-size: 1.2rem;
      }
      .checkListItem {
        font-size: 1rem;
        font-weight: normal;
        display: flex;
        svg {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 0.5rem;
        }
      }
      .listContainer {
        display: flex;
        justify-content: space-between;
      }
      .listText {
        display: flex;
        flex-direction: column;
      }
      .listIcon img {
        width: 3rem;
        margin-top: 2rem;
      }
      .listTitle {
        margin: 2rem 0rem 1rem 0rem;
        font-weight: bold;
        font-size: 1.2rem;
      }
      .listItems {
        font-size: 1rem;
        font-weight: normal;
        display: flex;
        line-height: 2rem;
      }
    }
  }

  .button {
    color: white;
    text-transform: unset;
    border-radius: 2rem;
    padding: 0.2rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-content: center;
    margin: auto;
    margin-top: 1rem;
  }
}
