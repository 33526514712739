.Footer {
  background-color: #0000001f; /*var(--global-lightblue-color);*/
  text-align: left;
  color: var(--global-blue-color);
  height: 100%;
  width: 100%;
  display: flex;
  color: black;
  padding: 1rem 4rem 0rem 4rem;

  .content {
    display: flex;
    margin-bottom: 0 !important;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
  }

  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .leco-logo-img {
    width: 20rem;
  }

  .left {
    display: flex;
    flex-direction: column;
  }

  .left-item {
    font-size: var(--text-size);
    font-weight: 600;
    line-height: 1rem;
  }

  .left-item p {
    font-size: var(--subtext-size);
    font-weight: 200;
  }

  .right {
    display: flex;
    flex-direction: row;
  }

  .link-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    margin-left: 2rem;
  }

  .link-name {
    font-weight: bold;
    font-size: var(--text-size);
  }

  .link-subname {
    font-weight: 200;
    font-size: var(--subtext-size);
  }

  .link-icon {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    svg {
      height: 2.6rem;
      width: 2.6rem;
      color: black;
    }
    img {
      height: 2.3rem;
    }
  }

  .app-stars {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: -0.5rem;
    span {
      font-size: 1rem;
      font-weight: bold;
    }
    svg {
      height: 1rem;
      width: 1rem;
      color: #fcbf02;
    }
  }

  a {
    color: var(--global-blue-color); /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    white-space: nowrap;
  }

  .copyright-item {
    margin-top: 4rem;
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .standard-item {
    font-size: 1rem;
  }

  .standard-tel-item {
    margin-top: 1rem;
    font-size: x-large;
  }
}

.modal-mentions-legales .ml-title {
  font-weight: bold;
  font-size: 1rem;
  color: var(--global-blue-color);
}

.modal-mentions-legales .ml-subText {
  font-size: 0.9rem;
  padding-left: 1.2rem;
  color: var(--global-blue-color);
}

.modal-mentions-legales .btn {
  background-color: var(--global-blue-color);
  margin: auto;
  border-radius: 2.5rem;
  height: 2rem;
  padding-bottom: 2rem;
  width: 5rem;
}

.modal-mentions-legales .modal-title {
  color: var(--global-blue-color);
}

@media (max-width: 575px) {
  .Footer {
    .copyright-item {
      font-size: 1rem;
      text-align: center;
    }

    .footer-content {
      flex-direction: column-reverse;
      gap: 2rem;
    }

    .right {
      justify-content: center;
      flex-direction: column;
    }

    .link-item {
      align-items: center;

      margin-left: 0rem;
    }

    .left-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}
