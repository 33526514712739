.QuiSommesNous {
  color: var(--global-blue-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  align-items: flex-start;

  .title {
    margin-top: 2rem;
    font-size: var(--title-size);
    font-weight: var(--title-weight);
    padding-left: 0;
    margin-bottom: 1rem;
    display: block;
    text-align: left;
  }

  .subtitle {
    margin-top: 0rem;
    font-size: 1.5rem;
  }

  .content {
    margin: auto;
    text-align: left;
    height: 100%;
    display: flex;
    padding: 0px;
  }

  .text {
    display: block;
    font-size: var(--subtext-size);
  }
  .text span {
    font-size: var(--subtitle-size);
    font-weight: 800;
  }

  .text-content {
    display: flex;
    flex-direction: column;
    flex: 0.4;
  }
  .photo-content {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    justify-content: center;
    align-items: center;
    align-items: stretch;
    margin-left: 1rem;
  }

  .photo-content img {
    width: 100%;
    align-items: stretch;
  }

  .photo-item-names {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    margin-top: 0.2rem;
  }

  .photo-item-name {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .photo-item-name span {
    font-size: 0.7rem;
    font-weight: 200;
  }

  a {
    color: grey;
    text-decoration: inherit; /* no underline */
    white-space: nowrap;
  }
}

@media (max-width: 575px) {
  .QuiSommesNous {
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 4%;
    padding-bottom: 4%;
    margin-top: 0rem;

    .title {
      margin-top: 2rem;
      font-size: 2.5rem;
      line-height: 2.5rem;
    }

    .text span {
      font-size: 1.5rem;
    }
    .text {
      margin-bottom: 2rem;
    }
    .content {
      flex-direction: column;
    }

    .photo-content {
      margin-left: 0rem;
    }
  }
}
