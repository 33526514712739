.Contact {
  color: var(--global-blue-color);
  text-align: left;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 0rem;

  .title {
    font-size: var(--title-size);
    font-weight: var(--title-weight);
  }

  .subtitle {
    display: flex;
    font-size: var(--subtitle-size);
    font-weight: var(--subtitle-weight);
    margin-top: 1rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
  }

  .left-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
  }

  .text {
    margin: 1.5rem 0rem 0rem 0rem;
    text-align: left;
    /*font-size: var(--text-size);*/
    font-weight: 600;
    font-size: 1.4rem;
  }

  .subtext {
    text-align: left;
    font-weight: normal;
    font-size: var(--subtext-size);
  }
  .subtext-mail {
    font-size: 1.2rem;
    font-weight: 200;
    margin: 0.5rem 0rem;
  }
  .subtext-tel img {
    width: 15rem;
  }

  .right-content {
    display: flex;
    width: 100%;
    flex: 1;
    gap: 1rem;
  }

  .text-item {
    display: flex;
    gap: 1rem;
    --bs-gutter-x: 1.5rem;
    input,
    textarea {
      border-color: #67c2e2;
    }
    .form-control:focus {
      box-shadow: 0 0 0 0.25rem #67c2e22b;
    }
  }

  .item {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .img-connexion {
    width: 3rem;
    padding: 0;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .form-butt {
    display: flex;
    align-items: flex-end;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .img-connexion:hover {
    opacity: 0.5;
  }

  a {
    color: var(--global-blue-color); /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    white-space: nowrap;
  }
}

@media (max-width: 991px) {
  .Contact {
    .content {
      flex-direction: column;
      gap: 2rem;
    }

    .right-content {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

@media (max-width: 575px) {
  .Contact {
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 4%;
    padding-bottom: 4%;
    margin-top: 0rem;

    .title {
      margin-top: 2rem;
      font-size: 2.5rem;
      padding-left: 0;
      margin-bottom: 0rem;
    }

    .subtitle {
      margin-top: 0rem;
      font-size: 1.5rem;
    }

    .content {
      flex-direction: column;
      gap: 2rem;
    }

    .right-content {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
