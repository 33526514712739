.Home {
  color: var(--global-blue-color);
  min-height: var(--global-page-height);
  /*background-attachment:fixed;*/

  /*position: absolute;*/
  display: flex;
  justify-content: center;
  max-width: 100%;

  .content {
    width: 100%;
    padding-right: 4%;
  }

  .title {
    text-align: right;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    margin-top: 1rem;
  }

  .title p {
    color: #b0d14c;
    font-size: 3.2rem;
    font-weight: bold;
  }

  .sub-title {
    text-align: right;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .leader-img {
    width: 2rem;
    margin-left: 1rem;
  }
}

.fixedbg {
  position: fixed;
  z-index: -1;
  height: 80%;
  display: flex;
  bottom: 0px;
}

@media (max-width: 575px) {
  .Home {
    .title {
      text-align: left;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 30%;
      padding-left: 4%;
    }

    .sub-title {
      text-align: left;
      font-size: 1rem;
      padding-left: 4%;
    }

    .leader-img {
      width: 1.5rem;
      margin-left: 0.5rem;
    }
  }
}
