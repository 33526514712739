.merge-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: url("../assets/fonds/fond_decoupe.jpg");
  background-color: beige;
  
  background-size: cover;
  background-position: center;*/

  .wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}
