.Solution {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;

  .title {
    text-align: left;
    font-size: var(--subtitle-size);
    line-height: 2rem;
    font-weight: var(--subtitle-weight);
    z-index: 2;
    position: relative;
  }

  .title span {
    font-size: var(--title-size);
    font-weight: var(--title-weight);
    z-index: 2;
    position: relative;
  }

  .subtitle {
    margin-top: 0.5rem;
    text-align: left;
    z-index: 2;
    position: relative;
  }

  .content {
    padding: 0px;
    display: flex;
    margin-bottom: 0;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.crm-list {
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
  align-items: center;
  flex-wrap: wrap;
}

.mob-list {
  width: 100%;
  gap: 1rem;
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: flex-end;
}

.mob-items {
  position: absolute;
  width: 70%;
  left: 0;
  top: 4rem;
}

.mob-img {
  display: flex;
  justify-content: flex-end;
}

.mob-img img {
  max-height: 35rem;
  margin-top: -6rem;
  margin-right: 2rem;
}

.mob-img-item {
  display: flex;
}

.mob-img-item-resp {
  display: none;
}

.color-green {
  color: #b0d14c;
}

.color-lightblue {
  color: #67c2e2;
}
.color-blue {
  color: #292b59;
}
.color-grey {
  color: #5f5f5f;
}
.color-orange {
  color: #f3b540;
}

.bg-color-green {
  background-color: #b0d14c;
}

.bg-color-lightblue {
  background-color: #67c2e2;
}
.bg-color-blue {
  background-color: #292b59;
}
.bg-color-grey {
  background-color: #5f5f5f;
}
.bg-color-orange {
  background-color: #f3b540;
}

.bg-color-green {
  background-color: #b0d14c;
}

@media (max-width: 991px) {
  .Solution {
    .mob-img-item {
      display: flex;
    }

    .mob-img img {
      max-height: 27rem;
      margin-top: 3rem;
      margin-right: -1rem;
    }

    .mob-img-item-resp {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .Solution {
    .title span {
      margin-top: 2rem;
      font-size: 2.5rem;
      padding-left: 0;
      margin-bottom: 0rem;
    }

    .title {
      margin-top: 0rem;
      font-size: 1.5rem;
      margin-bottom: 0px;
      padding: 0% 4%;
    }

    .width-50p {
      width: 50%;
    }

    .subtitle {
      margin-top: 0rem;
      padding: 0% 4%;
    }

    .mob-img-item {
      display: none;
    }

    .mob-img img {
      max-height: 27rem;
      margin-top: -7rem;
      margin-right: 2rem;
    }

    .mob-img-item-resp {
      display: flex;
      width: 8rem;
      margin-right: 2rem;
    }
  }

  .crm-list {
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    padding: 0% 4%;
  }
  .mob-list {
    justify-content: flex-start;
    padding: 0% 4%;
    flex-direction: column-reverse;
  }
  .mob-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    top: 2rem;
  }
  .mob-img {
    justify-content: flex-end;

    img {
      margin: 0px;
      width: 50%;
      margin-top: -34%;
      margin-bottom: -4rem;
    }
  }

  .mob-items {
    position: relative;
  }
}
